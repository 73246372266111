import React, { useEffect, useState } from 'react'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { Swiper, SwiperSlide } from 'swiper/react'
import { BLOG_LISTING_ITEMS_PER_PAGE } from 'utils/settings'
import { useTrans } from 'hooks'
import { useGetCategorieNewsQuery, useGetAllNewsQuery } from 'gql/graphql'
import { FiltroBoolean, Loader, NotificationBar, Button } from 'components/atoms'
import { ArticoloCard } from 'components/molecules'
import { useIsMobile } from 'hooks'

interface Props {
  className?: string
}

const BlogListing = (props: Props) => {
  const { className = '' } = props
  const isMobile = useIsMobile()

  const t = useTrans()
  const [filtriAttivi, setFiltriAttivi] = useState([])
  const [articoli, setArticoli] = useState([])

  const categorieNewsQuery = useGetCategorieNewsQuery()

  const { data, loading, error, refetch, fetchMore } = useGetAllNewsQuery({
    variables: {
      offset: 0,
      first: BLOG_LISTING_ITEMS_PER_PAGE,
    },
    notifyOnNetworkStatusChange: true,
  })

  const toggleFiltro = (id) => {
    if (filtriAttivi.indexOf(id) > -1) setFiltriAttivi(filtriAttivi.filter((x) => x !== id))
    else setFiltriAttivi(filtriAttivi.concat([id]))
  }

  const loadMore = () => {
    fetchMore({
      variables: {
        offset: articoli.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          allNews: {
            ...fetchMoreResult.allNews,
            edges: [...prev.allNews.edges, ...fetchMoreResult.allNews.edges],
          },
        })
      },
    })
  }

  useEffect(() => {
    setArticoli([])
    const variables = {
      offset: 0,
      first: BLOG_LISTING_ITEMS_PER_PAGE,
    };
  
    if (filtriAttivi.length > 0) {
      variables.categorieIds = filtriAttivi;
    }
    else{
      variables.categorieIds = null
    }
    refetch(variables).then((result) => {
      if (result.data?.allNews?.edges) {
        setArticoli(result.data.allNews.edges)
      }
    })
  }, [filtriAttivi])

  useEffect(() => {
    if (data?.allNews?.edges) setArticoli(data.allNews?.edges)
  }, [data?.allNews?.edges])

  return (
    <div className={`blog-listing ${className ? ` ${className}` : ''}`}>
      <div className="blog-listing__filters">
        <span className="blog-listing__filters__label">{t('Filtra per')}</span>
        <div className="blog-listing__filters__list">
          {categorieNewsQuery.data?.categorieNews?.edges?.length > 0 ? (
            <Swiper spaceBetween={8} slidesPerView="auto" watchOverflow  simulateTouch={isMobile}>
              <SwiperSlide>
                <FiltroBoolean
                  label={t('Tutti')}
                  onClick={() => setFiltriAttivi([])}
                  active={filtriAttivi.length === 0}
                />
              </SwiperSlide>
              {categorieNewsQuery.data.categorieNews.edges.map((cat) => (
                <SwiperSlide key={`blog-listing__filters__item__${cat.node.pk}`}>
                  <FiltroBoolean
                    label={cat.node.nome}
                    onClick={() => toggleFiltro(cat.node.pk)}
                    active={filtriAttivi.indexOf(cat.node.pk) > -1}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <FiltroBoolean label={t('Tutti')} active={true} />
          )}
        </div>
      </div>
      {loading && !data ? (
        <Loader className="blog-listing__loader" />
      ) : error ? (
        <div className="blog-listing__error">
          <NotificationBar variant="alert" label={error} />
        </div>
      ) : (
        <>
          <div className="blog-listing__list">
            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
              <Masonry>
                {articoli.map((articolo) => (
                  <div
                    className="blog-listing__item"
                    key={`blog-listing__item__${articolo.node.pk}`}
                  >
                    <ArticoloCard articolo={articolo.node} />
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </div>
          {data.allNews?.pageInfo?.hasNextPage && (
            <div className="blog-listing__more-cta">
              <Button
                label={t('Carica altri')}
                variant="secondary"
                onClick={loadMore}
                loading={loading}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default BlogListing
